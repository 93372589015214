// Colors

$white: #fff;
$black: #000;
$beige: #ededeb;
$dark-beige: #b5b5b5;
$light-gray: #ecedee;

$main-color: #131313;
$color-accent: #ca0814;

$main-text-color: #666;

$gray-black-gradient: linear-gradient(90deg, #474646, #131313);
$red-gradient: linear-gradient(#cc3333, #ca0814);
$white-gray-gradient: linear-gradient(#fff, #fefefe);

$services-line :#dbdbdb;

$footer-top-bg: #191919;
$footer-bottom-bg: #111111;