//Helpers
.mt10 {
    margin-top: 10px;
}

.mb10 {
    margin-bottom: 10px;
}

.mtb10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.mt20 {
    margin-top: 20px;
}

.mb20 {
    margin-bottom: 20px;
}

.mtb20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.pt20 {
    padding-top: 20px;
}

.pb20 {
    padding-bottom: 20px;
}

.ptb20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.inline {
    display: inline-block;
}

.underline {
    border-bottom: 2px solid #ca0814;
}

//Gravity Forms Styles
.input-field-gravity {
    input {
        padding: 16px 10px !important;
        width: 100% !important;
        border: 1px solid #ccc;
        margin: 10px 0 0px;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    	}
    label {
        display: none !important;
    	}
	}
.gform_wrapper .gform_footer input.button {
    position: relative;
    background: linear-gradient(#cc3333, #ca0814);
    border: 2px solid #ca0814;
    font-size: 18px !important;
    color: #ededeb;
    font-weight: 500;
    padding: 10px 50px;
    transition: .3s ease-in-out;
    outline: none;
    border-radius: 4px;
    text-transform: uppercase;
    &:hover {
    	background: linear-gradient(#fff, #fefefe);
	    color: #ca0814;
	    border: 2px solid #ca0814;
    }
}
.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield select {
    width: 100% !important;
    border: 1px solid #ccc;
    margin: 10px 0 0px;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    height: 65px;
    background-color: #fff;
    color: #757575;
}