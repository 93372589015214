//Buttons 

.btn-red {
    position: relative;
	background: $red-gradient;
	border: 2px solid #ca0814;
    font-size: 18px;
    color: $beige;
    font-weight: 500;
    padding: 10px 50px;
    transition: .3s ease-in-out;
    outline: none;
    &:hover {
    	background: $white-gray-gradient;
    	color: #ca0814;
    	border: 2px solid #ca0814;
    }
    &:after {
        position: absolute;
        top: 50%;
        right: 10%;
        margin-top: -6px;
        display: inline-block;
        content: " ";
        width: 12px;
        height: 12px;
        background: url(../img/btn-arr.png) no-repeat 90% center;
    }
}
