.footer {
    &--top {
        background-color: $footer-top-bg;
        padding: 0 0 40px;
        border-top: 10px solid #ca0814;
        h3 {
            font-size: 24px;
            padding-bottom: 10px;
            font-weight: 800;
            color: $light-gray;
        }
        h4 {
            font-size: 16px;
            color: $light-gray;
            font-weight: bold;
            text-transform: uppercase;
        }
        p {
            color: $light-gray;
            font-size: 16px;
            margin-bottom: 0px;
        }
        a {
            color: $light-gray;
        }
        .logo {
            margin: 20px 0 10px;
        }
        &__nav {
            float: left;
            padding: 0;
            ul {
                float: left;
                padding: 0;
                li {
                    list-style: none;
                    font-size: 14px;
                    line-height: 24px;
                    a {
                        color: $light-gray;
                        transition: .4s ease-in-out;
                    }
                    &:hover a {
                        color: #fff;
                        text-decoration: none;
                    }
                }
                &:first-child {
                        margin-right: 35px;
                }
            }
        }
        .socials {
            float: left;
            .icon {
                position: relative;
                width: 53px;
                height: 53px;
                float: left;
                margin-right: 10px;
                transition: .5s ease-in-out;
                cursor: pointer;
                &:hover {
                    transform: rotateY(360deg);
                }
                &.facebook {
                    background: url(../img/facebook-white.png) no-repeat;
                }
                &.google {
                    background: url(../img/google-white.png) no-repeat;
                }
                &.linkedin {
                    background: url(../img/linkedin-white.png) no-repeat;
                }
                &.rss {
                    background: url(../img/blog-white.png) no-repeat;
                }
            }
        }
    }
    &--bottom {
        background-color: $footer-bottom-bg;
        padding: 40px 0 30px;
        p {
            color: $light-gray;
            font-size: 13px;
            float: left;
        }
        .designed-by {
            p {
                float: right;
            }
            p,
            a {
            color: #555;
            }
        }
        nav {
            float: left;
            ul {
                float: left;
                display: inline-block;
                list-style: none;
                padding: 0;
                margin-left: 10px;
                li {
                    float: left;
                    padding: 0 10px;
                    border-left: 1px solid $light-gray;
                    a {
                        color: $light-gray;
                        transition: .5s ease-in-out;
                    }
                    &:hover a {
                            color: #fff;
                            text-decoration: none;
                        }
                }
            }
        }
        a {
            color: $light-gray;
        }
    }
}

@media screen and (max-width: 1170px) {
    .footer {
        text-align: center;
        img {
            margin: 0 auto;
        }
        &--top {
            .socials {
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: center;
                width: 100%;
            }
            &__nav {
                float: none;
                ul {
                    width: 100%;
                }
            }
            
        }
    }
} 

@media screen and (max-width: 780px) {
    .footer--top {
        .logo {
            margin: 20px auto 10px;
        }
    }
    .footer--bottom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
            float: none;
            text-align: center;
        }
        nav {
            float: none;
            display: flex;
            justify-content: center;
        }
        .designed-by p {
            float: none;
        }
    }
}
