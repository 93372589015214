//Header Styles

.header {
	position: relative;
	background: $white;
	border-bottom: 18px solid $color-accent;
	z-index: 10;
	&:after {
		position: absolute;
		bottom: -18px;
		left: 0;
		display: block;
		content: " ";
		width: 100%;
		height: 4px;
		background: $main-color;
	}
	.logo {
		margin: 15px 0;
	}
	.phone {
		display: inline-block;
		a {
			display: inline-block;
			font-size: 30px;
			font-weight: 600;
			color: $color-accent;
			text-decoration: none;
			margin-right: 25px;
		}
	}
	.socials {
		display: inline-block;
		.social-link {
			display: inline-block;
			width: 33px;
			height: 33px;
			margin: 0 5px;
			cursor: pointer;
			transition: .5s ease-in-out;
			&.fb {
				background: url(../img/facebook.png) no-repeat center;
				background-size: 100%;
			}
			&.google {
				background: url(../img/google.png) no-repeat center;
				background-size: 100%;
			}
			&.linkedin {
				background: url(../img/linkedin.png) no-repeat center;
				background-size: 100%;
			}
			&.rss-feed {
				background: url(../img/blog.png) no-repeat center;
				background-size: 100%;
			}
			&:hover {
				transform: rotateY(360deg);
			}
		}
	}
	.search-holder {
		display: inline-block;
		.search-btn {
			display: inline-block;
			width: 24px;
			height: 24px;
			background: url(../img/search-icon.png) no-repeat center;
			cursor: pointer;
			margin: 0 20px;
		}
		.search-form-holder {
			display: none;
			position: absolute;
			top: 100%;
			right: 0;
			width: 320px;
			background: $black;
			border: 1px solid $white;
			height: 60px;
			z-index: 10;
			padding: 10px 15px;
			text-align: left;
			input {
				padding: 8px 10px;
				border: 1px solid $color-accent;
				border-radius: 5px;
    			margin-right: 10px;
    			float: left;
				
				&.btn-red {
					padding: 5px 10px;
					margin-right: 0;
					&:hover {
				    	background: $white-gray-gradient;
				    	color: #ca0814;
				    	border: 2px solid #ca0814;
				    }
				}
			}
		}
	}
	.main-menu,
	.mobile-menu {
		> ul {
			position: relative;
			padding: 0;
			> li {
				font-family: 'Roboto', sans-serif;
				font-size: 16px;
				text-transform: uppercase;
				list-style-type: none;
				display: inline-block;
				a {
					position: relative;
					padding: 5px 10px;
					color: $black;
					cursor: pointer;
				}
				> a {
					padding: 20px 5px;
				}
				&.menu-item-has-children:hover > a:after {
					top: 63px;
					z-index: 20;
					left: 50%;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
					border-color: rgba(213, 4, 4, 0);
					border-top-color: $color-accent;
					border-width: 10px;
					margin-left: -10px;
				}
				> .sub-menu {
					display: none;
					position: absolute;
					top: 40px;
					width: 100%;
					min-height: 305px;
					border-top: 5px solid $color-accent;
					left: 0;
					background: $white;
					z-index: 11;
					padding: 50px;
					&.commercial {
					  	-moz-column-count: 3;
					  	-moz-column-gap: 20px;
					  	-webkit-column-count: 3;
					  	-webkit-column-gap: 20px;
					  	column-count: 3;
					  	column-gap: 20px;
					  	li a {
					  		padding: 0;
					  	}
					}
					li {
						list-style-type: none;
						text-align: left;
						line-height: 30px;
						font-size: 16px;
						> .sub-menu li {
							a {
								text-transform: none;
								font-size: 16px;
								&:hover {
									color: $color-accent;
								}
							}
						}
						> a {
							&:hover {
								color: $color-accent;
							}
							
						}
						>	.sub-menu-img {
							position: absolute;
						    right: 20px;
						    width: 350px;
						    height: 260px;
						    top: 20px;
						}
					}
				}

				&:hover > a,
				&.current-menu-item > a {
					text-decoration: none;
					color: $color-accent;
					
				}
				&:hover {
					.sub-menu {
						display: block;
					}
				}
			}
		}
	}
	.hamburger {
		display: none;
		outline: none;
	}
	.mobile-menu {
		display: none;
		ul {
			padding: 0;
			> li {
				position: relative;
				display: block;
				padding-right: 20px;

				&.menu-item-has-children:after {
					position: absolute;
					right: 0;
					top: 21px;
					margin-top: -3px;
					display: inline-block;
					content: " ";
					width: 9px;
					height: 6px;
					background: url(../img/menu-arr.png) no-repeat center;
					transition: .3s ease-in-out;
					transform: rotate(-90deg);
				}
				&.menu-item-has-children:hover {
					&:after {
						transform: rotate(0deg);
					}
					> a:after {
						display: none;
					}
				}
				a {
					text-decoration: none;
					display: block;
					padding: 10px;
				}
				.sub-menu {
					border: 0;
					min-height: 0;
					position: relative;
					top: 0;
					padding: 0 0 0 30px;
					> li {
						& > .sub-menu {
							display: none;
						}
						&:hover > .sub-menu {
							display: block;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.header {
		.phone {
			a {
				font-size: 25px;
				line-height: 29px;
			}
		}
		.main-menu {
			display: none;
		}
		.hamburger {
			display: inline-block;
		}
	}
}
@media screen and (max-width: 994px) {
	.header {
		.phone {
			a {
				font-size: 20px;
				line-height: 29px;
			}
		}
		.socials {
			display: none;
		}
	}
}
@media screen and (max-width: 767px) {
	.header {
		.phone {
			a {
				font-size: 16px;
				line-height: 29px;
			}
		}
		.search-holder {
			.search-btn {
				margin: 0 8px;
			}
		}
	}
}
@media screen and (max-width: 480px) {
	.header {
		.phone {
			a {
				margin-right: 0;
			}
		}
		.search-holder {
			display: none;
		}
		.hamburger {
			padding: 5px 0;
		}
	}
}
