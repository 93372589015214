.section {
    padding: 30px;
}
.page-title {
    display: inline-block;
    border-bottom: 2px solid #ca0814;
    padding: 20px 0;
    margin-bottom: 20px;
}

.top-section {
    .hero-slider,
    & .slick-slide {
        height: 820px;
    }
    .hero-slider {
        .slick-slide {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .slick-arrow {
            position: absolute;
            top: 50%;
            width: 40px;
            height: 73px;
            margin-top: -36px;
            z-index: 10;
            font-size: 0;
            border: 0;
            outline: none;
            transition: .3s ease-in-out;
        }
        .slick-prev {
            left: 10%;
            background: url(../img/prev-arr.png) no-repeat center;
            &:hover {
                left: 9%;
            }
        }
        .slick-next {
            right: 10%;
            background: url(../img/next-arr.png) no-repeat center;
            &:hover {
                right: 9%;
            }
        }
    }
    .top-img {
        height: 490px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

//Top Home CTA section
.top-cta {
    background: $main-color;
    .cta-holder {
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-top: -215px;
        z-index: 100;
        .cta-item {
            position: relative;
            text-align: center;
            color: $white;
            margin: 40px 20px;
            padding: 20px;
            border-radius: 10px;
            overflow: hidden;
            outline: none;
            &:hover .cta-img {
                transform: scale(1.2);
            }
            .cta-img {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: .3s ease-in-out;
            }
            h3 {
                font-size: 26px;
                font-weight: 600;
            }
            p {
                font-size: 14px;
                margin-bottom: 30px;
            }
        }
    }
}
.page-title {
    font-size: 36px;
    font-weight: 600;
    color: $white;
    &-holder {
        background: $main-color;
    }
}
.main-content {
    background: $main-color;
    color: $white;
    padding-bottom: 40px;
    h1 {
        display: inline-block;
        border-bottom: 2px solid #ca0814;
        padding: 20px 0;
        margin-bottom: 20px;
    }
    p {
        color: $dark-beige;;
        font-size: 16px;
        line-height: 28px;
    }
    a {
        color: $color-accent;
        &.btn-red {
            color: $white;
            &:hover {
                color: $color-accent;
            }
        }
    }
    &.inner-page {
        background: $white;
        padding-bottom: 0;
        h1 {
            font-size: 24px;
            line-height: 28px;
            color: $color-accent;
            border: none;
            padding: 0;
        }
        h2 {
            font-size: 20px;
            line-height: 28px;
            color: $color-accent;
        }
        p {
            font-size: 16px;
            line-height: 28px;
            color: $main-color;
            margin-bottom: 30px;
            text-align: justify;
        }
        ul li {
            font-size: 16px;
            line-height: 28px;
            color: $main-color;
        }
        .search-form input[type="text"] {
            padding: 11px 20px;
            display: inline-block;
            margin-right: 30px;
            margin-bottom: 20px;
            color: #131313;
        }
    }
}

.services-section {
    position: relative;
    height: 490px;
    .service-img {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &--beige {
        background: $beige;
        .service-img {
            left: 0;
        }
    }
    &--grey {
        background: $light-gray;
        .service-img {
            right: 0;
        }
    }
    .container {
        &, & > .row {
            height: 100%;
            .col-md-6 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
            }
        }
        h1,h2,h3 {
            font-size: 30px;
            font-weight: 600;
            padding: 20px 0;
            border-bottom: 2px solid #ca0814;
        }
        p {
            color: $main-text-color;
            font-size: 16px;
            line-height: 28px;
        }
    }
}

.partners-section {
	background: url(../img/partners-bg.jpg) no-repeat;
	width: 100%;
    height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .container, 
    .col-md-12,
    .partners-slider, .textwidget {
        height: 100%;
    }
    .partners-slider {
        display: flex;
        justify-content: center;
        align-items: center;
        .slick-track {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .slick-slide {
            padding: 20px;
        }
    }
}
.contacts-section {
    h3 {
		border-bottom: 2px solid #ca0814;
		padding: 15px 0;
	}
	form {
		margin-top: 30px;
	}
	.btn-red {
		margin: 5px auto 15px auto;
	    display: block;
	    position: relative;
	    width: 100%;
	}
}

.request-section {
	background: $gray-black-gradient;
    h2 {
    	font-size: 32px;
    	color: #fff;
    }
    p {
        font-size: 18px;
        color: $dark-beige;
    }
    .btn-red {
    	margin-top: 30px;
    	max-width: 100%;
    }
}
.form-control {
	padding: 25px 15px;
}

.sidebar {
    .menu {
        margin-top: 40px;
        > li {
            list-style-type: none;
            font-size: 16px;
            font-weight: 800;
            text-transform: uppercase;
            a {
                color: $main-color;
            }
            > .sub-menu {
                padding-left: 0;
                li {
                    list-style-type: none;
                    font-weight: 100;
                    text-transform: none;
                }
            }
        }
    }
}
@media screen and (max-width: 1200px) {
    .top-section {
        .hero-slider,
        & .slick-slide {
            height: 560px;
        }
    }
    .top-cta {
        .cta-holder {
            margin-top: 0;
        }
    }
}
@media screen and (max-width: 992px) {
    .services-section {
        padding: 40px 0;
        border-bottom: 1px solid $services-line;
        height: auto;
        .service-img {
            display: none;
        }
    }
	.contacts-section {
		h3 {
			text-align: center;
		}
	}
}
@media screen and (max-width: 768px) {
    .top-section {
        .hero-slider,
        & .slick-slide {
            height: 360px;
        }
    }
    .request-section {
    	.btn-red {
    		float: none !important;
    	}
    }
    .partners-section {
        height: 300px;
        background-attachment: scroll;
    }
}